import { Component, OnInit, HostListener,ElementRef,ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Tools } from "../../modules/tools";
import * as XLSX from "xlsx";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { catchError, finalize } from 'rxjs/operators';
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {HomeService } from "src/app/services/home.service";
import {AuthService } from "src/app/services/auth.service";
import * as FileSaver from 'file-saver';
import {MatPaginator, MatTableDataSource, MatSort } from "@angular/material";
import { Console } from "console";
export class searchField {
  email?: string;
  type?: string;
  action?:string;
  username:string;
  filename:string;
  pnrno:string;
  fileName:string;
  Data_Status_Completed?:string;
  Data_Status_Pending?:string;
  Data_Status_NotFound?:string;
  Total_Data?:string;
  data_remaining_xmltoread?:string;
}

export class Corporate {
  clientId?: string;
  contractExpiryDate?: string;
  currency?: Date;
  locationId?: Date;
  name?:string;
  navisionId?:string;
  status?:string;
  type?:string;

  

}
export class FileData {
  fileName?: string;
  status?: string;
  uploaddate?: String;

}

export class FileExcelData {
  Airline?: string;
  Message?: string;
  PNR_No?: String;
  Datastatus?:String;
  OFFICEID?:String;
  LastName_Email?:String;
  FlightType?:String;
  TravelStatus?:String;
  XML?:String;
  GenTS?:String;


}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})

    
// this InputVar is a reference to our input.

  
export class HomeComponent implements OnInit {
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3 text-center" },
    {
      columnHeader: "FileName",
      columnFieldName: "_id.fileName",
      columnClasses: "c-col-pc-26 text-center"
    },
    {
      columnHeader: "UploadTime",
      columnFieldName: "_id.uploaddate",
      columnClasses: "c-col-pc-15 text-center"
    },
    {
      columnHeader: "Status",
      columnFieldName: "_id.status",
      columnClasses: "c-col-pc-6 text-center"
    },
    {
      columnHeader: "Message",
      columnFieldName: "_id.Message",
      columnClasses: "c-col-pc-20 text-center"
    },
  ];

  gridcolsdetail: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3 text-center" },
    {
      columnHeader: "PNR No",
      columnFieldName: "PNR_No",
      columnClasses: "c-col-pc-8 text-center"
    },
    {
      columnHeader: "LastName/Email",
      columnFieldName: "Last_Name",
      columnClasses: "c-col-pc-10 text-center"
    },
    {
      columnHeader: "AirLine",
      columnFieldName: "AirLine",
      columnClasses: "c-col-pc-6 text-center"
    },
    {
      columnHeader: "Data Status",
      columnFieldName: "DataStatus",
      columnClasses: "c-col-pc-5 text-center"
    },

    {
      columnHeader: "OFFICE ID",
      columnFieldName: "OfficeId",
      columnClasses: "c-col-pc-7 text-center"
    },

    {
      columnHeader: "GenerationTime",
      columnFieldName: "GenTS",
      columnClasses: "c-col-pc-15 text-center"
    },

    {
      columnHeader: "TRAVLE-STATUS",
      columnFieldName: "TravelStatus",
      columnClasses: "c-col-pc-13 text-center"
    },

    {
      columnHeader: "Message",
      columnFieldName: "Message",
      columnClasses: "c-col-pc-30 text-center"
    },
  ];
  @ViewChild(MatPaginator) paginator:MatPaginator
  @ViewChild(MatSort) sort:MatSort
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  public data: any;
  pdfFileName:string;
  showPDFModal:boolean=false;
  filename:string;
  pnrImagefile:any;
  pnrno:string;
  username:string;
  file: any = null;
  loading:boolean;
  filelist:any;
  filedatalist: Array<any>;
  isUpload:boolean;
  fileexceldata:Array<FileExcelData>
  displayedColumns=['pnr_no','officeid','last_name']
  datasource:MatTableDataSource<any>
  isDownload:boolean
  isDetailScreen:boolean=false;
  searchField: searchField;
  corporatelist:Corporate[];
  dateNow: Date = new Date();
  page: GridPage;
  showModal: boolean;
  publicKey:string;
  _publicKey:string;
  keyButton:string;
  ImageBaseData:string | ArrayBuffer=null;
  Reports: string="Reports";
  allData: Array<FileData>;
  filedata:any;
  constructor(private route: Router,
    private homeservice: HomeService,
    private auth: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private sanitizer: DomSanitizer,
    public tools: Tools) {
     
      this.Reports="Reports";
      this.page = new GridPage();
      this.loading=false;
      this.keyButton="Add";
      this.searchField = new searchField();
      this.searchField.username = JSON.parse(sessionStorage.getItem('user'));
      this.searchField.type = "NS";
      this.searchField.email = "";
      this.searchField.action = "D";
      this.searchField.filename="NS";
      this.filename="";
      this.getFileList();
      this.getAllData();
      this.page.size = 10; //Default page size
     if(!this.searchField.username ){
        this.route.navigate(["/login"]);
      }
      else{
        this.username=JSON.parse(sessionStorage.getItem('user'));
      }
      if(this.searchField.action==="D"){
        this.Reports="Reports"

      }
  }
  onChange(event) {
   
     /* let me =this
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        console.log(reader.result);
        me.ImageBaseData=reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };*/
      let selectedFile = event.target.files[0];
      
      console.log(selectedFile);
      this.searchField.filename = selectedFile.name;
      var extension =  this.searchField.filename.substr( this.searchField.filename.lastIndexOf('.'));
      
      if(extension.toLowerCase()!=".csv"&& extension.toLowerCase()!=".xlsx" && extension.toLowerCase()!=".xls" && extension.toLowerCase()!=".xlsb")
      {
        console.log(extension);
        let ngmodaloptions: NgbModalOptions = {};
        const modalRefOk = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRefOk.componentInstance.title = "Information";
        modalRefOk.componentInstance.message =
          "Uploaded file type is not allowed";
        modalRefOk.componentInstance.btnCLass = "btn-info";
        modalRefOk.result.then(
          result => {
            this.myInputVariable.nativeElement.value = "";

          },
          reason => {
            this.myInputVariable.nativeElement.value = "";
          }
        );
      } else{
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64SelectedFile = <string>reader.result;
        var itemImagerawarray=base64SelectedFile.split(";");
         let  itemImagearray = itemImagerawarray[1].split(",");
          this.file = itemImagearray[1];
          console.log(itemImagearray[1]);
    }
  }
}
confirmation(){
  let ngmodaloptions: NgbModalOptions = {};
  ngmodaloptions.backdrop = "static";
  ngmodaloptions.size = "sm";

  const ngmodalref = this.ngbModalService.open(
    NgYesNoModalContent,
    ngmodaloptions
  );
  ngmodalref.componentInstance.title = "Confirmation";
  ngmodalref.componentInstance.message = "Are you sure to Upload the file?";
  ngmodalref.result.then(
    result => {
      if (result == "yes") {
        this.onSubmit();
      }
    },
    reason => {}
  );
}
  onSubmit(){
    this.loading = true;
    let fileUplodVM = "";
   // console.log(sessionStorage.getItem("currentUser"));
    if(this.searchField.action==="U"){
      if(this.ImageBaseData!=null)
      {     
        fileUplodVM =this.ImageBaseData.toString()
      }
      
      this.homeservice.uploadFile(this.file,this.searchField).pipe(finalize(() => { this.loading = false; })).subscribe(data => {
        if(data){
         
          this.myInputVariable.nativeElement.value = "";
          this.searchField.type = "-";
          this.searchField.email = "";
          this.searchField.action = "D";
          this.searchField.filename="-";
          this.searchField.pnrno="";
        }
        else{
          console.log("File upload failed")
        }
      })
    }
    if(this.searchField.action==="D"){
      console.log(this.searchField.filename)
    this.homeservice.getFile(this.searchField).pipe(finalize(() => { this.loading = false; })).subscribe(data => {
      // if(data["result"].content.booking.status!=="P"){
       // console.log(JSON.parse(sessionStorage.getItem('User')));
      // let startdate:string = this.tools.formatDateForName(this.searchField.stabhbbbbbbbbbbbbbbbh bbhgbbbrtdate);
      // let enddate:string = this.tools.formatDateForName(this.searchField.enddate);
       let currentdate:string = this.tools.formatDateForName(this.dateNow);
        if (data) { 

          var blob = data;
        FileSaver.saveAs(blob, this.searchField.filename);
        this.searchField.type = "-";
        this.searchField.email = "";
        this.searchField.action = "D";
        this.searchField.filename="-";
        this.searchField.pnrno="";
        } 
      })
    }
  }

  ngOnInit() {
    
  }
  onLogoutClick() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('currentUser');
    this.route.navigate(["/login"]);
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler(event) {}
  onBackButton(){
    this.isDetailScreen=false;
  }
  getFileList(){
    this.homeservice.getFileList().subscribe(data => {
      if (data) { 
        console.log(data)
        const filelist = data;
       console.log(filelist);
       this.filelist = filelist;
       
        //console.log(this.filelist);
      }
      })
  }
  getAllData(){
    this.homeservice.getAllData().subscribe(data => {
      if (data) { 
        const alldata = data['alluploadfiles'];
       console.log(alldata);
      this.allData = alldata;
       
        console.log(this.allData);
      }
      })
  }
  
  ismodified(){
    if(this.publicKey!=this._publicKey){
      return true;
    }else{
      return false;
    }
  }
  onRowDblClick(rowdata: any) {
    this.filename=rowdata._id.fileName;
    console.log(rowdata._id.fileName)
    this.getFileData(rowdata._id.fileName);
   //this.getFileAllData(rowdata._id.fileName);
    this.isDetailScreen = true;
    //this.router.navigate(['user']);
    //this.userComp.getUserDetail(this._userParam, this._action);
  }


 async downloadExcel  ( ) {
  console.log(this.filename)
    this.getFileAllData(this.filename);
    let curdate = new Date();
    let curdatestring = this.tools.formatDateToDDMMYY(curdate);
    const worksheet = XLSX.utils.json_to_sheet(this.fileexceldata);
    const workbook = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, this.filename);
  };

  createExcelData(dataArray){
    //  console.log(dataArray)
      if(dataArray){
        let fileexcellist: Array<FileExcelData> = new Array<FileExcelData>();
  for(let i=0;i<dataArray.length;i++){
          const fileexceldata: FileExcelData = new FileExcelData();
          fileexceldata.PNR_No = dataArray[i]._id.pnr_no;
          fileexceldata.LastName_Email = dataArray[i]._id.last_name;
          fileexceldata.Airline = dataArray[i]._id.airline;
          fileexceldata.Datastatus = dataArray[i]._id.datastatus;
          fileexceldata.OFFICEID = dataArray[i]._id.officeid;
          fileexceldata.TravelStatus = dataArray[i]._id.TravelStatus;
          fileexceldata.GenTS = dataArray[i]._id.GenTS;
          fileexceldata.XML = dataArray[i]._id.XML;
          fileexceldata.Message = dataArray[i]._id.Message;

          

          fileexcellist.push(fileexceldata)
  }
      this.fileexceldata = fileexcellist;
}
  }
  getFileAllData(filename:string){
    console.log(filename);
    this.homeservice.getFileAllData(filename).subscribe(data => {
      if (data) { 
        const filedata = data['uploadefileddata'];
       console.log(data);
      this.filedata = filedata;
       this.createExcelData(filedata);
       
      }
      })
  }

  getFileData(filename:string) {
    //this.chargerActionService.getChargingDetails(this.page, this.searchField).subscribe(data => {
    this.homeservice.getFilePNRData(this.page, filename).subscribe(data => {
    // if(data["result"].content.booking.status!=="P"){

      if (data["result"]) {
        
        console.log(data["result"]);
        this.filedatalist = data["result"];
        this.page.first = data["result"].first;
        this.page.last = data["result"].last;
        this.page.number = data["result"].number;
        this.page.numberOfElements = 10;
        this.page.offset = data["result"].offset;
        this.page.size = 10;
        this.page.totalElements = data["Total_Data"];
      //  this.page.totalPages = data["result"].totalPages;
      this.page.totalPages =Number(this.page.totalElements/10);
        this.searchField.Data_Status_Completed = data["Data_Status_Completed"];
        this.searchField.Data_Status_Pending = data["Data_Status_Pending"];
        this.searchField.Data_Status_NotFound = data["Data_Status_NotFound"];
        this.searchField.data_remaining_xmltoread = data ["data_remaining_xmltoread"];
        this.searchField. Total_Data = data["Total_Data"];
               if (this.page.number == 0) {
          this.gridComp.setPages();
        }
      } else {
        alert("No Data Found in this File!"); 
      }
      
    });
  }
  /*viewRecImg(obj:any){
    console.log(obj)
    this.showPDFModal=true;
  }*/
  hidePDF(){
    this.showPDFModal=false
  }
  viewRecImg(objval:any){
    const mediaType = 'image/png';
    console.log(objval.obj.PNR_No)
    this.homeservice.getScreenShort(objval.obj.PNR_No).subscribe(data => {
      // if(data["result"].content.booking.status!=="P"){
        if (data) {
      var blob = new Blob([data], { type: data.type });
      var unsafeImg = URL.createObjectURL(blob);
      this.pnrImagefile = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImg);
      console.log(this.pnrImagefile );

      let ngmodaloptions:NgbModalOptions={};
      ngmodaloptions.size='lg';
      this.showPDFModal = true;

        } else {
          alert("Failed to get Screen Short!")
        }
  })
}

onsuggestinput(suggestion:string){
console.log(suggestion)
  this.homeservice.getsuggestedata(suggestion).subscribe(data => {
    if (data) { 
   this.corporatelist = data["result"].searchResult;
   for(let i=0;i<this.corporatelist.length;i++){
   console.log(this.corporatelist[i]);
   }
    }
    })
  }
  
  changeAction(event){
    let action = event.target.value;
    console.log(action);
    if(action==="U"){
      this.isDownload=true;
      this.Reports="Upload File"
    }
    if(action==="D"){
      this.isDownload=false;
      this.Reports="Reports"
    }
  }
 
  pnrSearch(){
    console.log(this.searchField.pnrno)
    this.homeservice.pnrSearch( this.searchField.pnrno).subscribe(data => {
     console.log(data)
      if (data["result"]) {
        this.filedatalist = data["result"];
      }
      else {
        alert("Failed to get File Details!");
      }
    })
  }
  onKeyStoreSave() {
    if(this.publicKey!=null||this.publicKey!=""){
     this.homeservice.saveKey(this.username,this.publicKey).subscribe(data => {
      if (data && (data["message"]==="UserDataUpdated"||data["message"]==="UserDataSaved")) { 
       this.hide();
      }
      })
    }
  }

  
  showMod()
  {
    this.showModal = true; 
    // Show-Hide Modal Check
  }
  hide()
  {
    this.showModal = false;
  }
}
