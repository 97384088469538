export class OperatorModel {
  operatorId?: number;
  operatorName?: string;
  addressline1?: string;
  addressline2?: string;
  operatorPincode?: string;
  operatorCity?: string;
  operatorState?: string;
  operatorCountry?: string;
  taxDescription?: string;
  taxrate1?: string;
  creationDate?: Date;
  config?:string;
  lastModificationDate?: Date;
  parentOperator?: OperatorModel;
}
