import { Component, OnInit } from '@angular/core';
import {LoginUser} from '../../models/user.model';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})
export class UserprofileComponent implements OnInit {

  user : LoginUser=<LoginUser>JSON.parse(sessionStorage.getItem('currentUser'));

  constructor() { 
    console.log(this.user);
  }


  ngOnInit() {
  }

}
