import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { AppSettings } from "../config/AppSettings";
import { Tools } from '../modules/tools';
import {GridPage} from '../util/components/gridtable/gridtable.component';

@Injectable({
  providedIn: 'root'
})
export class FTransactionsService {

  constructor(private http: HttpClient, private appsetting: AppSettings,private tools: Tools) {}

 
  getFTransactionsDetails(pageinfo : GridPage, searchField) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("page", pageinfo.number.toString());
    httpParam=httpParam.append("size",pageinfo.size.toString());
    httpParam=httpParam.append("status",searchField.status);
   httpParam=httpParam.append("startTime", this.tools.formatStartDateToDDMMYY(searchField.startdate));
   httpParam=httpParam.append("stopTime",this.tools.formatEndDateToDDMMYY(searchField.enddate));    
    
    if (searchField.username) {
      httpParam=httpParam.append("appUserName",searchField.username);
    }
    return this.http.get( this.appsetting.get("server-url") + '/api/getftransdetails',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }

}
