import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppSettings } from '../config/AppSettings';

import {GridPage} from '../util/components/gridtable/gridtable.component';
import { Tools } from '../modules/tools';
import { map } from "rxjs/operators";
import { searchField } from '../components/home/home.component';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient, private appsetting : AppSettings,private tools: Tools) { }

  getFile(searchField) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    //httpParam=httpParam.append("username", JSON.parse(JSON.stringify(sessionStorage.getItem('Token'))));
    httpParam=httpParam.append("fileName", searchField.filename);
   // httpParam=httpParam.append("type",searchField.type.toString());

    
    return this.http.get( this.appsetting.get("server-url") + '/api/v1/download',{headers : header, observe : "body", params : httpParam,responseType : "blob"})
    .map(res=>res);
  }

  getSGData(){

  }
  getFileList() {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    return this.http.get( this.appsetting.get("server-url") + '/api/v1/listfile',{headers : header, observe : "body",responseType : "json"})
    .map(res=>res);
  }
  getAllData() {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    return this.http.get( this.appsetting.get("server-url") + '/api/v1/alldata',{headers : header, observe : "body",responseType : "json"})
    .map(res=>res);
  }

  getFilePNRData(pageinfo : GridPage,filename:string) {
    console.log(filename)
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("fileName", filename);

    httpParam=httpParam.append("page", pageinfo.number.toString());
    httpParam=httpParam.append("size",pageinfo.size.toString());
  

    return this.http.get( this.appsetting.get("server-url") + '/api/v1/page_number',{headers : header, observe : "body",params : httpParam,responseType : "json"})
    .map(res=>res);

  }
  getFileAllData(filename:string) {
    console.log(filename)
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("fileName", filename);
    return this.http.get( this.appsetting.get("server-url") + '/api/v1/filedata',{headers : header, observe : "body",params : httpParam,responseType : "json"})
    .map(res=>res);

  }
    uploadFile(file:any,searchField:searchField) {
      console.log(file);
    let header = new HttpHeaders();
    const body = {
      file: file,
      filename: searchField.filename,
      email : searchField.email

    };
   // header.set('Content-Type', undefined) ;
   // const formData:FormData = new FormData(); 
    //header.append('Accept', 'multipart/form-data');
    // Store form name as "file" with file data
   // formData.append(file.name,file);
    //formData.append("Sample-Data.xlsx","");
    //formData.append("email", "xyz@123.com");
   // console.log(formData.get("file"));
    return this.http.post( this.appsetting.get("server-url") + '/api/v1/uploadfile',body,{headers:header})
    .pipe(
      map(res => res)
    )
  }
  saveKey(username,key) {
    let header = new HttpHeaders();
    const body = {
      user: username,
      key: key
    };
    return this.http.post<any>( this.appsetting.get("server-url") + '/updateKS',body,{headers:header})
    .pipe(
      map(data => {
        // login successful if there's a jwt token in the response
        if (data && data.success) {
          return data;
        }  
      })
    )
  }
  pnrSearch(pnrno:string) {
    
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("pnrno", pnrno);
    
    return this.http.get( this.appsetting.get("server-url") + '/api/v1/pnrsearch',{headers : header, observe : "body",params : httpParam,responseType : "json"})
    .map(res=>res);
  }
 
  getsuggestedata(suggestion) {
    let header = new HttpHeaders();
    //let ssoToken = '112ea900-6eac-41d4-8346-ba4ac9d35cd0'
   let ssoToken  = JSON.parse(JSON.stringify(sessionStorage.getItem('Token')));
    const body = JSON.stringify({
        "query": {
            "searchPhrase": suggestion,
            "component": "COMPANY"
        },
    
        "filters": [
            {
                "operator": "AND",
                "values": [
                    "ACTIVE"
                ],
                "key": "status"
            },
    
            {
                "key": "ssoToken",
                "values": [
                  ssoToken
                ],
    
                "operator": "AND"
            }
        ] 
    });
    return this.http.post<any>( this.appsetting.get("server-url") + '/getSuggest',body,{headers:header})
    .pipe(
      map(data => {
        // login successful if there's a jwt token in the response
        if (data && data.success) {
          return data;
        }  
      })
    )
  }
  getScreenShort(pnrno:string) {
    
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("pnrno",pnrno);
    
    return this.http.get( this.appsetting.get("server-url") + '/api/v1/getscreenshort',{headers : header, observe : "body",params : httpParam,responseType : "blob"})
    .map(res=>res);
  }
 
}
