import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injectable, APP_INITIALIZER } from "@angular/core";

import { AuthGuard } from "./guards/auth.guard";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { UserComponent } from "./components/user/user.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomFormsModule } from "ng5-validation";
//import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UserlistComponent } from "./components/userlist/userlist.component";
import { GridtableComponent } from "./util/components/gridtable/gridtable.component";
//import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { AuthService } from "./services/auth.service";
import { UserService } from "./services/user.service";
import { XhrInterceptorService } from "./services/xhr-interceptor.service";
import { global } from "./modules/global";
import { ImageconfigService } from "./services/imageconfig.service";
import { AppSettings } from "./config/AppSettings";
import { MenuService } from "./services/menu.service";
import { Tools } from "./modules/tools";
import { PageheaderComponent } from "./util/components/pageheader/pageheader.component";
import { UserroleComponent } from "./components/userrole/userrole.component";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "./util/components/modalcomponent/modal-content";
import { JwtModule } from "@auth0/angular-jwt";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import {
  ConfirmService,
  ConfirmModalComponent,
  ConfirmState,
  ConfirmTemplateDirective
} from "./util/confirm-modal-and-service";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ObjectArraySort } from "./util/pipe/pipetransform.pipe";
import { ChartsModule } from "ng2-charts";
import { ChartModule } from "angular-highcharts";
import { StationsService } from "./services/Stations.Service";
import { ProfileOcppService } from "./services/ProfileOcpp.service";
import { KnobComponentDirective } from "./lib/knob.directive";
import { UserprofileComponent } from "./components/userprofile/userprofile.component";
import { ResetpasswordComponent } from "./components/resetpassword/resetpassword.component";

import { OperatorService } from "./services/operator.service";

import { CityService } from "./services/City.Service";

import { ManageChargerService } from "./services/ManageCharger.service";
import { ChargerService } from "./services/charger.service";
import { ChangepasswordComponent } from "./components/changepassword/changepassword.component";
import { AgmCoreModule } from "@agm/core";
import { ActivebookingService }   from "./services/activebooking.service";
import { FTransactionsService }   from "./services/ftransactions.service";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { AppUserService } from "./services/appUser.service";
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OCPPStatusMasterService } from "./services/ocpp.status.master.service";
import { ChargerActionQueueService } from "./services/charger.action.queue.service";
import { from } from 'rxjs';
import { FooterComponent } from './components/footer/footer.component';


const appRoutes: Routes = [   
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "reset-password", component: ResetpasswordComponent },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "footer", component: FooterComponent, canActivate: [AuthGuard] },
  { path: "user", component: UserlistComponent, canActivate: [AuthGuard] },
  { path: "role", component: UserroleComponent, canActivate: [AuthGuard] },
  { path: "changepassword", component: ChangepasswordComponent, canActivate: [AuthGuard] },
  { path: "profile", component: UserprofileComponent, canActivate: [AuthGuard]},
  { path: "user", component: UserlistComponent, canActivate: [AuthGuard] },
  { path: "role", component: UserroleComponent, canActivate: [AuthGuard] },
  ]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    UserlistComponent,
    GridtableComponent,
    HomeComponent,
    PageheaderComponent,
    UserroleComponent,
    NgYesNoModalContent,
    NgInformationModalContent,
    ConfirmModalComponent,
    ConfirmTemplateDirective,
    ObjectArraySort,
    KnobComponentDirective,
    UserprofileComponent,
    ResetpasswordComponent,
    ChangepasswordComponent,
    FooterComponent,
    
    
  ],
  imports: [
    NgbModalModule,
    BrowserModule,
    ChartsModule,
    ChartModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    CustomFormsModule,
    ReactiveFormsModule,
    //HttpModule,
    HttpClientModule,
    JwtModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    CdkTableModule,
    CdkTreeModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBA9AHukZCsxCapvUMUU2gwXXSUYZJbGUI"
    }),
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      preloadingStrategy: AppSettings
    })
  ],
  entryComponents: [
    NgYesNoModalContent,
    NgInformationModalContent,
    UserlistComponent,
    ConfirmModalComponent
  ],

  providers: [
    AuthGuard,
    AuthService,
    UserService,
    ProfileOcppService,
    global,
    StationsService,
    StationsService,
    Tools,
    MenuService,
    ConfirmService,
    ConfirmState,
    OperatorService,
    CityService,
    ManageChargerService,
    ChargerService,
    XhrInterceptorService,
    ImageconfigService,
    OCPPStatusMasterService,
    ChargerActionQueueService,
    ActivebookingService,
    FTransactionsService,
    AppUserService,
    
    AppSettings, //JwtHelper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XhrInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appSettingFactory,
      deps: [AppSettings],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule {}
export function appSettingFactory(provider: AppSettings) {
  return () => provider.load();
}
