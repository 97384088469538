import { Injectable } from '@angular/core';
import {HttpRequest,HttpInterceptor,HttpHandler, HttpHeaders, HttpResponse} from '@angular/common/http';
import { global } from '../modules/global';
import { AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

@Injectable()
export class XhrInterceptorService implements HttpInterceptor {

  constructor(private objGlobal : global,private authservice: AuthService,private router : Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {  
    let xhr : HttpRequest<any>;
 //   console.log(this.objGlobal.Auth.token);
    if(this.objGlobal.Auth.token) {
      if (!this.authservice.loggedin()) {
        this.router.navigate(['login']);
      }
      xhr = req.clone({headers : new HttpHeaders({"Content-Type":"application/json", 'X-Requested-With': 'XMLHttpRequest','Authorization' : 'Bearer ' + this.objGlobal.Auth.token})});
      //xhr = req.clone({headers : new HttpHeaders({"Content-Type": "multipart/form-data;boundary=--------------------------354299071663991969768646", 'X-Requested-With': 'XMLHttpRequest','Authorization' : 'Bearer ' + this.objGlobal.Auth.token,'Accept': '*/*'})});

    } else {
    xhr = req.clone({headers : new HttpHeaders({"Content-Type":"application/json", 'X-Requested-With': 'XMLHttpRequest'})});
    }
    return next.handle(xhr);
  }
}

