import { Injectable } from "@angular/core";
import { AppSettings } from "../config/AppSettings";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker/public_api";
import { DatePipe } from "@angular/common";
import { isObject } from "rxjs/internal/util/isObject";
import { SimpleMessage } from "./util.class";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "../util/components/modalcomponent/modal-content";
import { ReadtsValue } from "../models/common.model";
declare var $: any;

class RouteParam {
  receiver?: string;
  param?: any;
}
@Injectable()
export class Tools {
  public routeMenu: any;
  public routeParam: RouteParam;
  public bsDatepickerConfig: Partial<BsDatepickerConfig>;
  constructor(
    private appsettings: AppSettings,
    private ngbModalService: NgbModal
  ) {
    this.setbsDatePickerConfig();
  }
  sortObject(arrObj: Array<any>, field: string, ignoreCase: boolean = true) {
    if (!field) {
      field = "itemName";
    }

    if (arrObj) {
      arrObj.sort((a: any, b: any) => {
        if (ignoreCase) {
          if (a[field] < b[field]) {
            return -1;
          } else if (a[field] > b[field]) {
            return 1;
          } else {
            return 0;
          }
        } else {
          if (a[field].toLowerCase() < b[field].toLowerCase()) {
            return -1;
          } else if (a[field].toLowerCase() > b[field].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
    return arrObj;
  }

  getrouteMenu() {
    let obj = {};
    for (var prop in this.routeMenu) {
      obj[prop] = this.routeMenu[prop];
    }
    return obj;
  }
  getMenuName(page: string) {
    let menuName: string;
    if (this.routeMenu) {
      if (this.routeMenu["page"] == page) {
        menuName = this.routeMenu["menuName"];
      }
    }
    if (!menuName) {
      menuName = this.appsettings.getPageMenuName(page);
    }
    return menuName;
  }
  setRouteMenu(menuName: string, page: string) {
    this.routeMenu = {
      page: page,
      menuName: menuName
    };
  }
  getObjParam(receiver: string) {
    if (this.routeParam && this.routeParam.receiver == receiver) {
      return this.routeParam.param;
    }
  }
  getMenuNamePage(menuName: string) {
    return this.appsettings.getMenuNamePage(menuName);
  }
  jquerydatepicker() {
    var dtpickerItem = $(".datepicker");
    var prm: any = {};
    prm.dateFormat = "dd-mm-yy";
    prm.changeMonth = true;
    prm.changeYear = true;
    //console.log("my column");
    for (var i = 0; i < dtpickerItem.length; i++) {
      var element = dtpickerItem[i];
      if (element.attributes.minDate) {
        prm.minDate = element.attributes.minDate.value;
      }
      if (element.attributes.maxDate) {
        prm.maxDate = element.attributes.maxDate.value;
      }
      dtpickerItem.datepicker(prm);
    }
  }
  setbsDatePickerConfig() {
    this.bsDatepickerConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD-MMM-YYYY",
        showWeekNumbers: false,
        rangeInputFormat: "DD-MMM-YYYY"
      }
    );
  }
  formatDateToDDMMMYY(val: Date) {
    let datepipe = new DatePipe("en");
    return datepipe.transform(val, "yyyy-MM-dd");
    
  }
  formatDateForName(val: Date) {
    let datepipe = new DatePipe("en");
    return datepipe.transform(val, "dd-MMM-yyyy_h:mm:ssa");
  }
  formatDateToDDMMYY(val: Date) {
    let datepipe = new DatePipe("en");
    return datepipe.transform(val, "dd-MM-yyyy");
  }
  formatStartDateToDDMMYY(val: Date) {
    let datepipe = new DatePipe("en");
    return datepipe.transform(val, "dd-MMM-yyyy 00:00:00");
  }
  formatEndDateToDDMMYY(val: Date) {
    let datepipe = new DatePipe("en");
    return datepipe.transform(val, "dd-MMM-yyyy 23:59:59");
  }
  getDateOnly(val: Date) {
    return new Date(this.formatDateToDDMMMYY(val));
  }
  copyObject(obj: Object) {
    return JSON.parse(JSON.stringify(obj));
  }

  copyFullObject(obj: Object) {
    let newobj: any = undefined;
    if (obj) {
      if (obj instanceof Array) {
        newobj = [];
      } else {
        newobj = {};
      }

      Object.keys(obj).forEach(keyval => {
        if (obj[keyval] instanceof Date) {
          if (obj[keyval]) {
            newobj[keyval] = new Date(obj[keyval]);
          }
        } else if (obj[keyval] instanceof File) {
          if (obj[keyval]) {
            newobj[keyval] = Object.assign(obj[keyval]);
          }
        } else if (typeof obj[keyval] == "object") {
          newobj[keyval] = this.copyFullObject(obj[keyval]);
        } else if (Array.isArray(obj[keyval])) {
          let arr = new Array();
          let aritm: Array<any> = obj[keyval];
          aritm.forEach(itm => {
            if (itm instanceof Date) {
              arr.push(new Date(itm));
            } else if (isObject(itm)) {
              arr.push(this.copyFullObject(itm));
            } else {
              arr.push(new Date(itm));
            }
          });
        } else {
          newobj[keyval] = obj[keyval];
        }
      });
    }
    return newobj;
  }
  simpleMessage(objSimpleMsg: SimpleMessage) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.size = "lg";
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = objSimpleMsg.title;
    modalRef.componentInstance.message = objSimpleMsg.message;
    modalRef.componentInstance.btnCLass = objSimpleMsg.btnClass;
    modalRef.result.then(
      result => {
        if (objSimpleMsg.fnResult) {
          objSimpleMsg.fnResult(objSimpleMsg._scope);
        }
      },
      reason => {
        if (objSimpleMsg.fnReason) {
          objSimpleMsg.fnReason();
        }
      }
    );
  }

  isDateSame(date1: Date, date2: Date) {
    if (date1 && !date2) {
      return false;
    } else if (!date1 && date2) {
      return false;
    } else if (!date1 && !date2) {
      return true;
    } else if (
      this.formatDateToDDMMMYY(date1) == this.formatDateToDDMMMYY(date2)
    ) {
      return true;
    }
    return false;
  }
  getChartForTopItemMultiValSameDate(
    fromDate: Date,
    tillDate: Date,
    data: ReadtsValue
  ) {
    fromDate = this.getDateOnly(fromDate);
    tillDate = this.getDateOnly(tillDate);
    var days = Math.abs(
      ((tillDate.valueOf() - fromDate.valueOf()) / 24) * 60 * 60 * 1000
    );
    var dateCounter: Date = new Date(fromDate);
    let arrDate: Array<Date> = Array<Date>();
    let arrValue: Array<number> = Array<number>();
    let retvalues: ReadtsValue = new ReadtsValue();
    let labels: string[] = new Array<string>();
    while (dateCounter <= tillDate) {
      let readts: Date = new Date(dateCounter);
      labels.push(this.formatDateToDDMMMYY(readts));
      let val: number = 0;
      arrDate.push(readts);
      arrValue.push(val);
      dateCounter = new Date(dateCounter.valueOf() + 24 * 60 * 60 * 1000);
    }
    if (data && data.readts)
      for (let i = 0; i < data.readts.length; i++) {
        for (let j = 0; j < arrDate.length; j++) {
          if (this.isDateSame(data.readts[i], arrDate[j])) {
            if (data.value[i]) {
              const datavalue = +data.value[i].toFixed(2);
              if (datavalue > arrValue[j]) {
                arrValue[j] = datavalue;
              }
            }
          }
        }
      }
    retvalues.readts = arrDate;
    retvalues.value = arrValue;
    retvalues.labels = labels;
    return retvalues;
  }
  getChartData(fromDate: Date, tillDate: Date, data: ReadtsValue) {
    fromDate = this.getDateOnly(fromDate);
    tillDate = this.getDateOnly(tillDate);
    var days = Math.abs(
      ((tillDate.valueOf() - fromDate.valueOf()) / 24) * 60 * 60 * 1000
    );
    var dateCounter: Date = new Date(fromDate);
    //dateCounter.setDate(fromDate.valueOf())
    let arrDate: Array<Date> = Array<Date>();
    let arrValue: Array<number> = Array<number>();
    let retvalues: ReadtsValue = new ReadtsValue();
    let labels: string[] = new Array<string>();
    while (dateCounter <= tillDate) {
      let readts: Date = new Date(dateCounter);
      labels.push(this.formatDateToDDMMMYY(readts));
      let val: number = 0;
      if (data && data.value && data.value) {
        let nofound: boolean = true;
        for (let i: number = 0; i < data.value.length && nofound; i++) {
          if (this.isDateSame(readts, data.readts[i])) {
            val = +data.value[i].toFixed(2);
            nofound = false;
          }
        }
      }
      arrDate.push(readts);
      arrValue.push(val);
      dateCounter = new Date(dateCounter.valueOf() + 24 * 60 * 60 * 1000);
    }
    retvalues.readts = arrDate;
    retvalues.value = arrValue;
    retvalues.labels = labels;
    return retvalues;
  }

  defaultdateTimeFormat(val: Date) {
    if (val) {
      let datetimeval = new Date(val);
      let datepipe = new DatePipe("en-IN");
      return datepipe.transform(datetimeval, "dd-MMM-yyyy hh:mm:ss");
    }
  }

  bookingStatus(val: String) {
    if (val) {
      if (val=="C"){
      return "Completed";
      }
      else if (val=="S"){
      return "New";
      }
      else if (val=="R"){
      return "Running";
      }
      else if (val=="P"){
        return "Paid";
        }
        else if (val=="F"){
          return "Failed";
          }
      }
    }
    transactionStatus(val: String) {
      if (val) {
        if (val=="I"){
        return "Initiated";
        }
        else if (val=="P"){
        return "Success";
        }
        else if (val=="F"){
        return "Failed";
        }
      }
      }
}
