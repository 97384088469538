import { OperatorModel } from "./operator.model";
export class AppUserModel {
    username?:string;
    firstName?:string;
    lastName?:string;
    email?:string ;
    status?:string;
    userType?:string;
    operator?: OperatorModel;
    
  
   }
   